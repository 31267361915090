input[type="text"],
input[type="password"],
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 2px solid #ddd;
  box-sizing: border-box;
  transition: border-bottom-color 0.3s;
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
  outline: none;
  border-bottom-color: #18130775;
}

.button {
  background-color: white;
  color: black;
  border: 2px solid #18130775;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #3a0d0d;
  color: white;
}
